import { useState, useEffect, useRef } from "react";
import * as d3 from "d3-geo";
import styled from "styled-components";

import countries from "../countries";
import states from "../states";
import cloudList from "../cloud-list";

const Wrapper = styled.div`
  position: relative;
  overflow: hidden;
  svg {
    position: ${(props) => (props.fixed ? "fixed" : "relative")};
  }
  @media (max-width: 650px) {
    svg {
      position: relative;
    }
  }
`;

function Map({ cloud }) {
  const isWide = window.innerWidth > 650;
  const margin = isWide ? 50 : 20;

  const containerRef = useRef();
  const svgRef = useRef();

  const [countryPath, setCountryPath] = useState([]);
  const [statePath, setStatePath] = useState([]);
  const [cloudPoints, setCloudPoints] = useState([]);

  useEffect(() => {
    if (!containerRef.current) return;

    let width, height;
    if (cloud) {
      width = containerRef.current.clientWidth;
      height = isWide ? 300 : 250;
    } else {
      width = isWide ? 400 : window.innerWidth - margin * 2;
      height = isWide ? window.innerHeight : 400;
    }

    svgRef.current.setAttribute("width", `${width}px`);
    svgRef.current.setAttribute("height", `${height}px`);
    if (!cloud) containerRef.current.style.width = `${width}px`;

    let points = cloudList
      .filter((c) => {
        if (cloud) return c.date === cloud.date;
        else return true;
      })
      .map((c) => c.latlng);
    points = [...new Set(points)]
      .map((c) => c.split(",").map((d) => +d))
      .map((c) => [c[1], c[0]]);

    let extent = points;

    if (points.length === 1) {
      // make a fake extent
      const p = points[0];
      const m = 10;
      extent = [
        [p[0] - m, p[1] - m],
        [p[0] + m, p[1] + m],
      ];
    }

    const bounds = {
      type: "FeatureCollection",
      features: [...extent].map((p) => ({
        type: "Feature",
        geometry: {
          type: "Point",
          coordinates: p,
        },
      })),
    };

    const projection = d3.geoAlbers().fitExtent(
      [
        [margin, margin],
        [width - margin, height - margin],
      ],
      bounds
    );

    const path = d3.geoPath(projection);

    setCountryPath([path(countries)]);
    setStatePath([path(states)]);

    setCloudPoints(points.map((p) => projection(p)));

    // eslint-disable-next-line
  }, [containerRef.current]);

  const size = cloud ? 8 : isWide ? 6 : 5;

  return (
    <Wrapper fixed={!!!cloud} ref={containerRef}>
      <svg ref={svgRef}>
        {countryPath.map((d, i) => (
          <path
            d={d}
            stroke="#aaa"
            strokeWidth=".8"
            strokeDasharray="1 4"
            fill="none"
            key={`country${i}`}
          />
        ))}
        {statePath.map((d, i) => (
          <path
            d={d}
            stroke="#888"
            strokeWidth=".8"
            strokeDasharray="1 4"
            fill="none"
            key={`state${i}`}
          />
        ))}
        {cloudPoints.map((d, i) => (
          <path
            d={`M0,0 -${size},-${size * 2} ${size},-${size * 2}Z`}
            transform={`translate(${d.join(", ")})`}
            fill="white"
            key={`cloud${i}`}
          />
        ))}
      </svg>
    </Wrapper>
  );
}

export default Map;
