import styled from "styled-components";

import Dither from "./Dither";
import PrevNext from "./PrevNext";
import Map from "./Map";

const Position = styled.div`
  display: flex;
  justify-content: space-between;
  color: white;
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;
const Description = styled.div`
  padding: 40px 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
  h1 { font-family: monospace; font-size: 32px; }
  h2 { color: #DCE2E7; margin-bottom: 40px; }
  @media (max-width: 650px) {
    padding: 20px;
    h1 { font-size: 28px; }
    h2 { font-size: 24px; margin-bottom: 20px; }
  }
`;
const Tagline = styled.div`
  font-size: 18px;
  font-style: italic;
  color: #DCE2E7;
  @media (max-width: 650px) {
    font-size: 14px;
    margin: 40px 20px;
  }
`;

function CloudPage({ cloud }) {

  let content;
  if (window.innerWidth < 650) {
    content = (
      <>
        <Description>
          <div>
            <PrevNext date={cloud.date} />
            <h1>{cloud.date}</h1>
            <h2>{cloud.location}</h2>
          </div>
        </Description>
        <Dither file={cloud.file} kernel={cloud.kernel} />
        <div style={{ marginTop: "40px" }}><Map cloud={cloud} /></div>
        <Tagline>oh, to be a cloud on an overcast day</Tagline>
      </>
    );
  } else {
    content = (
      <>
        <Description>
          <div>
            <PrevNext date={cloud.date} />
            <h1>{cloud.date}</h1>
            <h2>{cloud.location}</h2>
            <Map cloud={cloud} />
          </div>
          <Tagline>oh, to be a cloud on an overcast day</Tagline>
        </Description>
        <Dither file={cloud.file} kernel={cloud.kernel} />
      </>
    );
  }

  return (
    <Position>
      {content}
    </Position>
  )
};

export default CloudPage;
