import { useState, useEffect, useRef } from "react";
import styled from "styled-components";

// import cloudIMG from "./images/2022-04-24.jpg";

const Wrapper = styled.div`
  border: 10px solid white;
  position: relative;
  background: black;
  canvas,
  svg {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

function Dither({ file, kernel }) {
  const [data, setCloudData] = useState();
  const imageRef = useRef();
  const canvasRef = useRef();
  const svgRef = useRef();

  const maxSize = Math.min(window.innerHeight, window.innerWidth);
  const size = maxSize - 20;

  const onLoad = (e) => {
    const canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");
    let imgCTX = imageRef.current.getContext("2d");

    imgCTX.drawImage(e.target, 0, 0, size, size);
    ctx.drawImage(e.target, 0, 0, size, size);

    setCloudData({
      data: ctx.getImageData(0, 0, size, size),
      width: size,
      height: size,
    });
  };

  //     // greyscale??

  //     // let imgData = imgCTX.getImageData(0, 0, ctx.canvas.width, ctx.canvas.height);
  //     // let pixels = imgData.data;
  //     // for (var i = 0; i < pixels.length; i += 4) {

  //     //   let lightness = parseInt((pixels[i] + pixels[i + 1] + pixels[i + 2]) / 3);

  //     //   pixels[i] = lightness - 10;
  //     //   pixels[i + 1] = lightness - 10;
  //     //   pixels[i + 2] = lightness + 20;
  //     // }
  //     // imgCTX.putImageData(imgData, 0, 0);

  useEffect(() => {
    if (!data) return;
    floydSteinberg();
    outputSVG();
    // eslint-disable-next-line
  }, [data]);

  const floydSteinberg = () => {
    const canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");
    ctx.canvas.style.width = `${data.width * 1}px`;
    ctx.canvas.style.height = `${data.height * 1}px`;
    ctx.canvas.style.imageRendering = "pixelated";
    let clone = new ImageData(
      new Uint8ClampedArray(data.data.data),
      data.width,
      data.height
    );
    function px(x, y) {
      return x * 4 + y * data.width * 4;
    }

    for (let y = 0; y < data.height; y++) {
      for (let x = 0; x < data.width; x++) {
        let oldPixel = clone.data[px(x, y)];
        let newPixel = oldPixel > 125 ? 255 : 0;
        clone.data[px(x, y)] =
          clone.data[px(x, y) + 1] =
          clone.data[px(x, y) + 2] =
            newPixel;
        let quantError = oldPixel - newPixel;
        clone.data[px(x + 1, y)] =
          clone.data[px(x + 1, y) + 1] =
          clone.data[px(x + 1, y) + 2] =
            clone.data[px(x + 1, y)] + (quantError * kernel[0]) / 16;
        clone.data[px(x - 1, y + 1)] =
          clone.data[px(x - 1, y + 1) + 1] =
          clone.data[px(x - 1, y + 1) + 2] =
            clone.data[px(x - 1, y + 1)] + (quantError * kernel[1]) / 16;
        clone.data[px(x, y + 1)] =
          clone.data[px(x, y + 1) + 1] =
          clone.data[px(x, y + 1) + 2] =
            clone.data[px(x, y + 1)] + (quantError * kernel[2]) / 16;
        clone.data[px(x + 1, y + 1)] =
          clone.data[px(x + 1, y + 1) + 1] =
          clone.data[px(x + 1, y + 1) + 2] =
            clone.data[px(x + 1, y + 1)] + (quantError * kernel[3]) / 16;
      }
    }
    ctx.putImageData(clone, 0, 0);
  };

  const outputSVG = () => {
    const canvas = canvasRef.current;
    let ctx = canvas.getContext("2d");
    let pixels = ctx.getImageData(0, 0, data.width, data.height);
    let w = data.width * 4;
    let p = ``;

    let spacer = 2;

    for (let i = 0; i < pixels.data.length; i += w * spacer) {
      let y = Math.floor(i / w);
      p += `M0 ${y}`;
      let down = false;
      for (let j = i; j < i + w; j += 8) {
        if (pixels.data[j] === 0) {
          if (down === false) {
            p += `M ${(j % w) / 4} ${y} `;
            down = true;
          }
        } else {
          if (down === true) {
            p += `L ${(j % w) / 4} ${y} `;
            down = false;
          }
        }
      }
    }

    const path = svgRef.current;
    path.setAttribute("d", p);
  };

  return (
    <Wrapper style={{ width: size, height: size }}>
      <canvas
        ref={canvasRef}
        width={size}
        height={size}
        style={{ display: "none" }}
      />
      <svg width={size} height={size}>
        <path ref={svgRef} stroke="#fff" strokeWidth="1.5" fill="none" />
      </svg>
      <canvas
        ref={imageRef}
        width={size}
        height={size}
        style={{ opacity: 0.76 }}
      />

      <img
        alt="cloud"
        src={`${process.env.PUBLIC_URL}/images/${file}.jpg`}
        onLoad={onLoad}
        style={{ display: "none" }}
      />
    </Wrapper>
  );
}

export default Dither;
