import styled from "styled-components";
import { Link } from "react-router-dom";

const Wrapper = styled.div`
  display: flex;
  color: white;
  .banner {
    text-decoration: none;
    position: fixed;
  }
  @media (max-width: 650px) {
    flex-direction: column;
    min-height: 100vh;
    .banner {
      position: relative;
    }
  }
`;
const Contents = styled.div`
  flex: 1;
  margin-left: 60px;
  @media (max-width: 650px) {
    margin-left: 0;
  }
`;
const Banner = styled.div`
  width: 60px;
  height: 100vh;
  background: white;
  writing-mode: vertical-rl;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  overflow: hidden;
  &:hover { background: #eee; }
  @media (max-width: 650px) {
    height: 60px;
    width: 100%;
    writing-mode: initial;
  }
`;
const Text = styled.div`
  transform: rotate(180deg);
  font-size: 38px;
  display: flex;
  align-items: center;
  -webkit-text-fill-color: #DCE2E7; /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #B6C3CE;
  @media (max-width: 650px) {
    transform: rotate(0deg);
    font-size: 28px;
  }
`;
const Emoji = styled.div`
  transform: rotate(-180deg);
  font-size: 40px;
  margin: 20px;
  @media (max-width: 650px) {
    transform: rotate(0deg);
    margin: 10px;
  }
`;

function App({ children }) {
  return (
    <Wrapper>
      <Link className="banner" to="/">
        <Banner>
          <Text>
            <Emoji>☁️</Emoji>
            <Emoji>☁️</Emoji>
            <Emoji>☁️</Emoji>
            cloudyvision 
            <Emoji>☁️</Emoji>
            <Emoji>☁️</Emoji>
            <Emoji>☁️</Emoji>
          </Text>
        </Banner>
      </Link>
      <Contents>
        { children }
      </Contents>
    </Wrapper>
  );
}

export default App;
