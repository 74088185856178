import { useMemo } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import cloudList from "../cloud-list";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  a { color: #ccc; text-decoration: none; }
  a:hover { color: white; }
  span {
    font-size: 22px;
    line-height: 12px;
  }
`;

function PrevNext({ date }) {
  const index = useMemo(() => {
    return cloudList.findIndex(c => c.date === date);
  }, [date])

  return (
    <Wrapper>
      <div>
        { index > 0 &&
          <Link to={`/cloud/${cloudList[index-1].date}`}><span>⤂</span> prev</Link>
        }
      </div>
      <div>
        { index < cloudList.length - 1 &&
          <Link to={`/cloud/${cloudList[index+1].date}`}>next <span>⤃</span></Link>
        }
      </div>
    </Wrapper>
  );
}

export default PrevNext;
