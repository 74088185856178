import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import './index.css';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import Landing from "./components/Landing";
import CloudPage from "./components/CloudPage";
import cloudList from "./cloud-list";

// <Route component={NotFound404} />

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <App>
        <Switch>
          <Route path="/" exact children={<Landing />} />
          { cloudList.map((l,i) => (
            <Route
              key={`link${i}`}
              path={`/cloud/${l.date}`}
              children={<CloudPage cloud={l} />}
            />
          ))}
        </Switch>
      </App>
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
