import styled from "styled-components";
import { Link } from "react-router-dom";

import cloudList from "../cloud-list";
import Map from "./Map";

const Position = styled.div`
  padding: 40px 60px;
  font-size: 18px;
  a {
    display: block;
    color: white;
    text-decoration: none;
  }
  @media (max-width: 650px) {
    padding: 40px 20px;
  }
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 650px) {
    flex-wrap: wrap;
  }
`;
const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
`;
const Emoji = styled.div`
  font-size: 32px;
  opacity: .6;
  @media (max-width: 650px) {
    display: none;
  }
`;
const DateName = styled.div`
  font-family: monospace;
  font-size: 16px;
  @media (max-width: 650px) {
    font-size: 14px;
  }
`;
const Location = styled.div`
  color: #DCE2E7;
  margin-top: .5em;
  font-size: 13px;
  @media (max-width: 650px) {
    font-size: 12px;
  }
`;
const Tagline = styled.div`
  color: #DCE2E7;
  font-style: italic;
  font-size: 18px;
  margin-bottom: 40px;
`;
const IMG = styled.div`
  background-image: url(${props => props.src});
  width: 200px;
  height: 200px;
  background-size: cover;
  background-position: 50%;
  opacity: .7;
  @media (max-width: 650px) {
    width: 140px;
    height: 140px;
  }
`;
const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 40px;
  a {
    &:hover {
      ${IMG}, ${Emoji} {
        opacity: 1;
      }
    }
  }
  @media (max-width: 650px) {
    gap: 30px;
    margin-bottom: 40px;
  }
`;
const Footer = styled.div`
  color: white;
  text-align: center;
  font-family: monospace;
  margin: 80px 0 40px;
  font-size: 16px;
  a {
    display: inline;
    color: white;
    text-decoration-color: #888;
    text-decoration: underline;
  }
`;

const Landing = () => (
  <Position>
    <Tagline>behold, the glory of the stratosphere</Tagline>

    <Flex>
      <List>
        {cloudList.map((l,i) => (
          <Link key={`link${i}`} to={`/cloud/${l.date}`}>
            <IMG src={`${process.env.PUBLIC_URL}/thumbs/${l.file}.jpg`} />
            <Text>
              <span>
                <DateName>{l.date}</DateName>
                <Location>{l.location}</Location>
              </span>
              <Emoji>☁️</Emoji>
            </Text>
          </Link>
        ))}
      </List>

      <div>
        <Map />
      </div>
    </Flex>
    <Footer>
      another project by <a href="https://rachelbinx.com/">rachel binx</a>
    </Footer>
  </Position>
);

export default Landing;
